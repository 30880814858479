<template>
  <div class="calipers" v-if="!$store.state.loading">
    <div class="cover" data-aos="fade-up" data-aos-duration="1000">
      <img :src="images[0]" alt="CarboBrake Calipers"/>
    </div>
    <h1 data-aos="zoom-in" data-aos-duration="1000">{{ $t('calipers-title') }}</h1>

    <div class="section">
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('calipers-section1-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('calipers-section1-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[1]" alt="CarboBrake Details"/>
      </div>
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('calipers-section2-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('calipers-section2-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000">
        <img :src="images[2]" alt="CarboBrake Internal Fluid"/>
      </div>
      <div
        class="section-title side"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('calipers-section3-title')"
      ></div>
      <div
        class="section-paragraph side"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('calipers-section3-paragraph')"
      ></div>
    </div>

    <div class="section">
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000"><img :src="images[3]" alt="CarboBrake Color"/></div>
      <div
        class="section-title full"
        data-aos="fade-right"
        data-aos-duration="1000"
        v-html="$t('calipers-section4-title')"
      ></div>
      <div
        class="section-paragraph full"
        data-aos="fade-left"
        data-aos-duration="1000"
        v-html="$t('calipers-section4-paragraph')"
      ></div>
      <div class="section-image" data-aos="fade-up" data-aos-duration="1000" data-aos-delay="800">
        <img :src="images[4]" alt="CarboBrake Palette"/>
      </div>
    </div>
    <theta-carousel> </theta-carousel>
  </div>
</template>

<script>
import ThetaCarousel from '../components/ThetaCarousel.vue'
export default {
  components: {
    ThetaCarousel
  },
  data() {
    return {
      images: [
        require('../assets/calipers_cover.png'),
        require('../assets/410_Esploso_2020.jpg'),
        require('../assets/410_internal_fluid.jpg'),
        require('../assets/Look.jpg'),
        require('../assets/palette.png')
      ]
    }
  },
  mounted() {
    //LOAD ALL IMAGES IN PAGE
    this.$store.dispatch('LOAD_IMAGES', this.images).then(() => {
      this.$nextTick(() => {
        setTimeout(() => {
          this.$store.commit('setFooterVisible')

          let event
          if (typeof Event === 'function') {
            event = new Event('resize')
          } else {
            //IE
            event = document.createEvent('Event')
            event.initEvent('resize', true, true)
          }
          window.dispatchEvent(event)
        }, 1100)
      })
    })
  }
}
</script>

<style lang="scss"></style>
